.calculator-result {
    display: flex;
    justify-content: center;

    &__cover {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        background-color: var(--text_secondary);
        width: 80%;
        height: 70vh;
        border-radius: 10px;
    }
}
